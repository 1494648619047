<template>
  <div id="app" class="box">
      <router-view :key="key"/>
  </div>
</template>

<script>

export default {
  name: "App",
  
  computed:{
    key(){
      return this.$route.path+Math.random();
    }
  }
};
</script>
<style scoped>
* {
  font-family: "NewFonts";
  font-size: 18px;
  user-select: none;
}
#app {
  min-height: 100%;
  display: flex;
}

</style>
