import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/font.css';
import * as echarts from 'echarts'
import '@/config/rem' 
import axios from 'axios';
import VueLazyload from 'vue-lazyload';  


Vue.prototype.$echarts = echarts 
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$http = axios;

Vue.use(VueLazyload, {  
  preLoad: 1.3,  
  error: 'dist/error.png',  
  loading: 'dist/loading.gif',  
  attempt: 1,  
});  

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})