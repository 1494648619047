import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode:'history',
  routes: [
    {
      path: '/',
      name: '首页',
      redirect:'/index'
    },
    {
      path: '/index',
      name: '首页',
      component: () => import("@/pages/index.vue")
    }, {
      path: '/m_index',
      name: '移动端首页',
      component: () => import("@/pages/mobile_index.vue")
    }, {
      path: '/about',
      name: '关于我们',
      component: () => import("@/pages/about.vue")
    }, {
      path: '/about/profile',
      name: 'profile',
      component: () => import("@/pages/about/profile.vue")
    }, {
      path: '/about/organize',
      name: 'organize',
      component: () => import("@/pages/about/organize.vue")
    }, {
      path: '/about/partner',
      name: 'partner',
      component: () => import("@/pages/about/partner.vue")
    }, {
      path: '/news',
      name: '新闻资讯',
      component: () => import("@/pages/news.vue")
    }, {
      path: '/news/company',
      name: 'company',
      component: () => import("@/pages/news/company.vue")
    }, {
      path: '/news/industry',
      name: 'industry',
      component: () => import("@/pages/news/industry.vue")
    }, {
      path: '/news/company/info/:id',
      name: 'companyDetail',
      component: () => import("@/pages/news/detail.vue")
    }, {
      path: '/news/industry/info/:id',
      name: 'industryDetail',
      component: () => import("@/pages/news/detail.vue")
    }, {
      path: '/group',
      name: '专家团队',
      component: () => import("@/pages/group.vue")
    }, {
      path: '/group/:id',
      name: 'card',
      component: () => import("@/pages/group/card.vue")
    }, {
      path: '/research',
      name: '研究咨询',
      component: () => import("@/pages/research.vue")
    }, {
      path: '/research/card:id',
      name: 'researchCard',
      component: () => import("@/pages/research/card.vue")
    }, {
      path: '/product',
      name: '产品开发',
      component: () => import("@/pages/product.vue")
    },{
      path: '/product/card:id',
      name: 'productCard',
      component: () => import("@/pages/product/card.vue")
    }, {
      path: '/observe',
      name: '新驰观察',
      component: () => import("@/pages/observe.vue")
    }, {
      path: '/achievement',
      name: '成果展示',
      component: () => import("@/pages/achievement.vue")
    }, {
      path: '/contact',
      name: '联系我们',
      component: () => import("@/pages/contact.vue")
    }, {
      path: '/contact/information',
      name: 'information',
      component: () => import("@/pages/contact/information.vue")
    }, {
      path: '/contact/map',
      name: 'map',
      component: () => import("@/pages/contact/map")
    }
  ]
})
